import { lazy } from 'react';

const Login = lazy(() => import('../../Components/Pages/login/Login'));

const CheckEmail = lazy(() => import('../../Components/Pages/Register/RegisterValidateEmail'));

// Step Register new user
const SessionExpired = lazy(() => import('../../Components/Pages/login/sessionExpired'));

const RegisterManager = lazy(() => import('../../Components/Pages/Register/RegisterManager'));
const RegisterTransferProcess = lazy(() =>
  import('../../Components/Pages/Register/RegisterTransferProcess')
);

const SimpleRegisterOneStep = lazy(() =>
  import('../../Components/Pages/SimpleRegister/SimpleRegisterOneStep')
);

// Steps recover password by phone:
const RecoverPasswordByPhoneSendPhone = lazy(() =>
  import('../../Components/Pages/recoverPasswordByPhone/RecoverPasswordByPhoneSendPhone')
);
const RecoverPasswordByPhoneCode = lazy(() =>
  import('../../Components/Pages/recoverPasswordByPhone/RecoverPasswordByPhoneCode')
);
const RecoverPasswordByPhoneSendNewPassword = lazy(() =>
  import('../../Components/Pages/recoverPasswordByPhone/RecoverPasswordByPhoneSendNewPassword')
);

// Steps recover password by email:
const RecoverPasswordByEmailSendEmail = lazy(() =>
  import('../../Components/Pages/recoverPasswordByEmail/recoverPasswordByEmailSendEmail')
);
const RecoverPasswordByEmailSendNewPassword = lazy(() =>
  import('../../Components/Pages/recoverPasswordByEmail/recoverPassswordByEmailSendNewPassword')
);
const LoginRevolupay = lazy(() => import('../../Components/Pages/login/LoginRevolupay'));

const NewPasswordSendity = lazy(() =>
  import('../../Components/Pages/recoverAccountRevolupay/NewPasswordSendity')
);
const RecoverPINRevolupay = lazy(() =>
  import('../../Components/Pages/recoverAccountRevolupay/RecoverPINRevolupay')
);
const RecoverPINRevolupaySms = lazy(() =>
  import('../../Components/Pages/recoverAccountRevolupay/RecoverPinRevolupaySms')
);

// generate payment url, Dtone
const GenerateUrl = lazy(() => import('../../Components/Pages/topUpPhone/GenerateUrl'));

// Error 404
const Error404 = lazy(() => import('../../Components/Pages/error404/Error404'));

/* const RegisterStepOneData = lazy(() =>
  import('../../Components/Pages/Register/RegisterStepOneData')
); */

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  // { path: `/`, exact: true, name: `Login`, element: RecoverPINRevolupaySms, redirect: `/login` },
  {
    path: `/recoverpin`,
    exact: true,
    element: RecoverPINRevolupay
  },
  {
    path: `/requestrecoverpin`,
    exact: true,
    element: RecoverPINRevolupaySms
  },
  {
    path: `/login/pin`,
    name: `Login PIN`,
    element: LoginRevolupay
  },
  {
    path: `/newlogin`,
    name: `newlogin`,
    element: Login
  },
  {
    path: `/login`,
    name: `Login`,
    element: Login
  },
  {
    path: `/checkemail`,
    exact: true,
    name: `Incidents`,
    element: CheckEmail
  },
  {
    path: `/signup/:route/:urlstep`,
    name: `Register`,
    element: RegisterManager
  },
  {
    path: `/signup`,
    name: `Signup simple one`,
    element: SimpleRegisterOneStep
  },
  /*   {
    path: `/register/code`,
    exact: true,
    name: `Register send code`,
    element: CreateStepOne2,
  },
  {
    path: `/register/transaccionalcode`,
    exact: true,
    name: `Register send code revolupey`,
    element: CreateStepTransaccionalCode,
  },
  {
    path: `/register/identification`,
    exact: true,
    name: `Register send identification`,
    element: CreateStepTwo,
  },
  {
    path: `/register/checkdata`,
    exact: true,
    name: `Register, check data send`,
    element: CreateStepCheckData,
  },
  {
    path: `/register/verification`,
    exact: true,
    name: `Register verification`,
    element: CreateStepThree,
  },
  {
    path: `/register/completed`,
    exact: true,
    name: `Register completed`,
    element: CreateStepCompleted,
  },
  {
    path: `/register/completed/checkemail`,
    exact: true,
    name: `Register completed`,
    element: CreateStepCompletedCheckEmail,
  }, */
  {
    path: `/recoverpassword/byphone`,
    exact: true,
    name: `Recover pass`,
    element: RecoverPasswordByPhoneSendPhone
  },
  {
    path: `/recoverpassword/byphone/sendcode`,
    exact: true,
    name: `Recover pass send code`,
    element: RecoverPasswordByPhoneCode
  },
  {
    path: `/recoverpassword/byphone/sendnewpassword`,
    exact: true,
    name: `Recover pass send new pass`,
    element: RecoverPasswordByPhoneSendNewPassword
  },
  {
    path: `/recoverpassword/byemail`,
    exact: true,
    name: `Recover pass`,
    element: RecoverPasswordByEmailSendEmail
  },
  {
    path: `/recoverpassword/byemail/sendnewpassword`,
    exact: true,
    name: `Recover pass send new pass`,
    element: RecoverPasswordByEmailSendNewPassword
  },

  {
    path: `/login/password`,
    exact: true,
    element: NewPasswordSendity
  },
  {
    path: `/404`,
    exact: true,
    name: `Error, page not found`,
    element: Error404
  },
  {
    path: `/session-expired`,
    name: `SessionExpired`,
    element: SessionExpired
  },
  {
    path: `/transferprocess`,
    name: `TransferProcess`,
    element: RegisterTransferProcess
  },
  {
    path: `/generateurl`,
    name: `Generate Url`,
    element: GenerateUrl
  }
];

export default routes;
