import React from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';

// routes config
import routes from './routes/publicRoutes';

const PublicLayout = () => {
  return (
    <Routes>
      {routes.map((route, idx) => {
        return (
          route.element && (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={<route.element />}
            />
          )
        );
      })}
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/login/old" element={<Navigate to="/login" replace />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default PublicLayout;
