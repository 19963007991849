import { removeSession } from '../utils/localStorage';

const handlerError = error => {
  if (error && error.response) {
    switch (error.response.status) {
      case 401:
        removeSession();
        window.location.href = '/login';
        break;
      default:
        return ''; // console.log(error);
    }
  }
};

export { handlerError };
