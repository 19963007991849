import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import config from './config/config';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'es',
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'es',
    debug: config.PRO !== 'true' && true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations', 'countries'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  });

export default i18n;
