import React from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';

// routes config
import routes from './routes/privateRoutes';

const PrivateLayout = () => {
  return (
    <>
      <Routes>
        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          );
        })}
        <Route path="/" element={<Navigate to="/profile" replace />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </>
  );
};

export default PrivateLayout;
