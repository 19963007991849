function getCookieValue(name) {
  const cookies = document.cookie.split(';');
  const matchingCookie = cookies.find(cookie => cookie.trim().startsWith(name + '='));
  if (matchingCookie) {
    const cookieValue = matchingCookie.split('=')[1];
    return JSON.parse(decodeURIComponent(cookieValue));
  }
  return null;
}

function getCookieConsentValue(name) {
  const cookies = document.cookie.split(';');
  const matchingCookie = cookies.find(cookie => cookie.trim().startsWith(name + '='));
  if (matchingCookie) {
    const cookieValue = matchingCookie.split('=')[1];
    return !!cookieValue;
  }
  return null;
}

const generateCookie = ({ consent }) => {
  const date = new Date();
  const days = 90;
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  const expires = 'expires=' + date.toUTCString();
  // seting cookie
  // document.cookie = `sendity-com=${JSON.stringify(
  //   consent
  // )};  ${expires}; path=/; domain=.sendity-test.com; sameSite=strict;`;

  //for testing
  document.cookie = `sendity-com=${JSON.stringify(
    consent
  )};  ${expires}; path=/; domain=.sendity.com; sameSite=strict;`;
};

const cookieConsentModeExist = getCookieConsentValue('sendity-com_consent');

function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}
if (!cookieConsentModeExist) {
  window.dataLayer = window.dataLayer || [];

  const consentObj = {
    functionality_storage: 'granted',
    ad_storage: 'denied',
    security_storage: 'granted',
    analytics_storage: 'denied',
    personalization_storage: 'granted',
    ad_user_data: 'denied',
    ad_personalization: 'denied'
  };
  //let know consent to google
  gtag('consent', 'default', consentObj);
  //generate cookie passport
  generateCookie({ consent: consentObj });
} else {
  // use passport cookie and let know consent to google

  gtag('consent', 'default', getCookieValue('sendity-com'));
}
