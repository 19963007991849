import Cookies from 'universal-cookie';
import config from '../config/config';

const loadToken = () => {
  try {
    const stor = localStorage.getItem('token');

    if (stor === null) return undefined;

    return stor;
  } catch (err) {
    return undefined;
  }
};

const setTempInfo = pin => {
  try {
    const serializedToken = pin;

    localStorage.setItem('tempInfo', pin);
    return { serializedToken };
  } catch (err) {}
};

const getTempInfo = () => {
  try {
    const temPin = localStorage.getItem('tempInfo');

    if (!temPin) return null;

    return temPin;
  } catch (err) {
    return null;
  }
};

const loadUser = () => {
  try {
    const stor = JSON.parse(localStorage.getItem('user'));

    if (stor === null) return undefined;

    return stor;
  } catch (err) {
    return undefined;
  }
};

const loadSession = () => {
  let session = { token: null, user: null };
  try {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    session.token = token;
    session.user = JSON.parse(user);
  } catch (err) {}
  return session;
};

const saveSession = (token, user) => {
  try {
    const serializedToken = token;
    const serializedUser = JSON.stringify(user);

    if (serializedToken) localStorage.setItem('token', serializedToken);
    if (serializedUser) localStorage.setItem('user', serializedUser);
    return { serializedToken, serializedUser };
  } catch (err) {}
};

const loadTransaction = () => {
  let transaction = null;
  try {
    transaction = JSON.parse(localStorage.getItem('transaction'));
  } catch (err) {}
  return transaction;
};

const saveTransaction = transaction => {
  try {
    const serializedTransaction = JSON.stringify(transaction);

    if (serializedTransaction) localStorage.setItem('transaction', serializedTransaction);
    return { serializedTransaction };
  } catch (err) {}
};

const removeSession = async () => {
  try {
    const cookies = new Cookies();
    cookies.remove('_rp_token_', {
      domain: config.COOKIE,
      sameSite: 'strict',
      secure: true
    });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('tempInfo');
    localStorage.removeItem('avatar64');
    localStorage.removeItem('_rp_token_');
  } catch (err) {}
};

const removeToken = () => {
  try {
    localStorage.removeItem('token');
  } catch (err) {}
};
const removeTempInfo = () => {
  try {
    localStorage.removeItem('tempInfo');
  } catch (err) {}
};

const removeUser = () => {
  try {
    localStorage.removeItem('user');
  } catch (err) {}
};
const getLang = () => {
  let lang = 'es';
  try {
    lang = localStorage.getItem('lang');
  } catch (err) {}
  if (!lang) lang = 'es';
  return lang;
};

const setField = (key, value) => {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch (err) {
    return err;
  }
};
const getField = key => {
  let item;
  try {
    item = localStorage.getItem(key);
  } catch (err) {
    return err;
  }
  if (!item) return false;
  return item;
};

const removeField = key => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    return err;
  }
};

const saveAvatar = avatar => {
  try {
    if (avatar) localStorage.setItem('avatar64', avatar);
    return { avatar };
  } catch (err) {}
};

const loadAvatar = () => {
  let avatar64 = null;
  try {
    avatar64 = localStorage.getItem('avatar64');
  } catch (err) {}
  return avatar64;
};

const saveTokenr = async (tokenr, dateExpires = 1) => {
  try {
    const cookies = new Cookies();
    const date = new Date();
    date.setDate(date.getDate() + dateExpires);
    cookies.set('_rp_token_', tokenr, {
      domain: config.COOKIE,
      sameSite: 'strict',
      expires: date,
      secure: true
    });
    localStorage.setItem('_rp_token_', tokenr);
  } catch (err) {
    console.log({ err });
  }
};

export {
  loadToken,
  loadUser,
  loadSession,
  saveSession,
  removeToken,
  removeUser,
  removeSession,
  setTempInfo,
  getTempInfo,
  removeTempInfo,
  getLang,
  setField,
  getField,
  removeField,
  saveTransaction,
  loadTransaction,
  saveAvatar,
  loadAvatar,
  saveTokenr
};
