const {
  VITE_APP_API_URL,
  VITE_APP_PRO,
  VITE_APP_NOINDEX,
  VITE_APP_apikey,
  VITE_APP_URL_CONNECTIF,
  VITE_APP_VERIDAS_PRO,
  VITE_SENTY_DNS
} = import.meta.env;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  DOMAIN: VITE_APP_API_URL || 'https://api.sendity.com/',
  // URL:
  apikey: VITE_APP_apikey || '$2b$10$FNI6X7eH3KIEIOyHy6CdBOtele8elwUq5Oyuc7CHKRQ1fWWvSePIi',
  COOKIE: VITE_APP_PRO === 'true' ? '.sendity.com' : 'insite.eu.ngrok.io',
  CONNECTIF:
    VITE_APP_URL_CONNECTIF ||
    'https://eu6-api.connectif.cloud:443/integration-type/system/scrippet-notification/f118b493-8a3a-4c17-a46c-95d6d15b2615?eventId=63dbd9646d06545985e8b900',
  NOINDEX: VITE_APP_NOINDEX === 'true' ? true : false,
  PRO: VITE_APP_PRO,
  VERIDAS:
    VITE_APP_VERIDAS_PRO === 'true'
      ? 'https://xpressid-web.eu.veri-das.com/v2/?access_token='
      : 'https://xpressid-web-work.eu.veri-das.com/v2/?access_token=',
  SENTRY_DNS:
    VITE_SENTY_DNS ||
    'https://55f9095f1123c106e78a6bd4b4dc5014@o4507684458070016.ingest.de.sentry.io/4507684713070672'
};
