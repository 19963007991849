import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import './themed/css/style.css';
import './themed/css/custom.css';
import './i18n';
import { loadSession } from './utils/localStorage';
import { PublicLayout, PrivateLayout } from './layout/index';
import config from './config/config';
import axios from 'axios';
import { handlerError } from './errors/handlerError';
import { CookieConsent } from './Components/cookies/CookieConsent';
import { HelmetProvider } from 'react-helmet-async';
const Authentication = ({ children }) => {
  const { user, token } = loadSession();
  const location = useLocation();
  let result = <PublicLayout />;
  if (token) result = children;
  if (location.search?.includes('calculator=true') && user)
    window.location.href = config.NOINDEX
      ? 'https://app-dev.sendity.com/transfer'
      : 'https://app.sendity.com/transfer';

  if (user && location.pathname.includes('login')) {
    result = <Navigate replace to="/" />;
  }
  if (!user && location.pathname.includes('profile')) {
    result = <Navigate replace to="/login" />;
  }
  if (user && user?.emailValid && /\/simple\//.test(location.pathname)) {
    result = <Navigate replace to="/" />;
  }
  if (
    user &&
    user?.kyc &&
    user?.kyc?.status === 'VALIDATED' &&
    /\/moneytrans\//.test(location.pathname)
  ) {
    result = <Navigate replace to="/" />;
  }
  if (
    user &&
    !user?.emailValid &&
    /\/simple\//.test(location.pathname) &&
    location.pathname !== '/signup/simple/6'
  ) {
    result = <Navigate replace to="/signup/simple/6" />;
  }
  if (
    user &&
    !user?.emailValid &&
    location.pathname !== '/signup/simple/6' &&
    location.pathname !== '/checkemail'
  ) {
    result = <Navigate replace to="/signup/simple/6" />;
  }
  if (!user && /\/moneytrans\//.test(location.pathname)) {
    result = <Navigate replace to="/" />;
  }
  if (!user && location.pathname === '/signup/simple/1') {
    result = <Navigate replace to="/signup" />;
  }

  if (user && location.pathname === '/signup') {
    result = <Navigate replace to="/" />;
  }

  return result;
};
export default function App() {
  useEffect(() => {
    const localStorageCountriesDataExist = !!localStorage.getItem('countries');

    if (localStorageCountriesDataExist && localStorage.getItem('countries').includes('gp')) return;

    axios({
      url: `${config.DOMAIN}mobile/countrieslocal `,
      method: 'GET',
      headers: {
        apikey: config.apikey
      }
    })
      .then(res => {
        const lowerCaseCountries = res.data.data.map(country => country.toLowerCase());
        localStorage.setItem('countries', JSON.stringify(lowerCaseCountries));
      })
      .catch(err => {
        handlerError(err);
      });
  }, []);

  const urlParams = new URLSearchParams(window?.location?.search);
  const lang = localStorage?.getItem('lang') || urlParams.get('lang') || 'es';

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Suspense fallback={null}>
          <Routes>
            <Route
              path="*"
              name="Home"
              element={
                <Authentication>
                  <PrivateLayout />
                </Authentication>
              }
            />
            {/* <Route element={<Authentication />} /> */}
          </Routes>
        </Suspense>
        <CookieConsent lang={lang} />
      </BrowserRouter>
    </HelmetProvider>
  );
}
